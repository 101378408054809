import useTranslation from "next-translate/useTranslation";

import styles from "./NavbarLogoWrapper.module.scss";

import Link from "@/dls/Link/Link";
import QuranTextLogo from "@/icons/quran-text-logo.svg";

const NavbarLogoWrapper = () => {
  const { t } = useTranslation("common");
  return (
    <Link href="/" className={styles.logoWrapper} title={t("quranehakeem-com")}>
      <QuranTextLogo style={{ height: "40px" }} />
    </Link>
  );
};

export default NavbarLogoWrapper;
