function ModalContentStep1(props) {
  const { handleBookTrialBtn } = props.value;
  return (
    <>
      <h2 className="qh-timeline-title">Steps to become a Better Muslim</h2>
      <div className="timeline">
        <div className="timeline__event timeline__event--type1">
          <div className="qh-timeline-item">
            <h3>
              <s>Desire to Learn Quran</s>
            </h3>
            <p>
              Your devotion has successfully got you searching for learning
              about Islam
            </p>
          </div>
        </div>
        <div className="timeline__event timeline__event--type2">
          <div className="qh-timeline-item">
            <h3>
              <s>Find Islamic Education Center</s>
            </h3>
            <p>
              If you have no Islamic Centres near you, you can learn online.
            </p>
          </div>
        </div>
        <div className="timeline__event timeline__event--type3">
          <div className="qh-timeline-item">
            <h3>Start Free Trail</h3>
            <p>
              Learn thru live classes and see how Quran can change your life
            </p>
          </div>
        </div>
        <div className="timeline__event timeline__event--type4">
          <div className="qh-timeline-item">
            <h3>Enroll in Full Course</h3>
            <p>
              Feeling satisfied with your choice for learning Islam? Continue
              your journey
            </p>
          </div>
        </div>
      </div>
      <div className="qh-timeline-footer">
        <p className="first-p">3 free one-on-one classes, then</p>
        <p className="second-p">
          <strong>$30/month</strong> (2 classes/week)
        </p>
        <a className="btn" onClick={handleBookTrialBtn}>
          Book Free Demo Class
        </a>
        <a className="below-btn" href="https://quranehakeem.com/fees-plans/">
          View all Plans
        </a>
      </div>
    </>
  );
}

export default ModalContentStep1;
