import { useEffect, useState } from "react";
import axios from "axios";
import NavigationDrawerItem from "./NavigationDrawer/NavigationDrawerItem";

function QHNav(props) {
  const [menuItems, setMenuItems] = useState(null);

  useEffect(() => {
    (async function () {
      if (!menuItems) {
        const { data } = await axios.get(
          "https://quranehakeem.com/wp-json/wpr/get_menu_html?id=14"
        );
        setMenuItems(data);
      }
    })();
  }, []);

  if (!menuItems) {
    return <></>;
  }

  function renderList(data) {
    return (
      <ul>
        {data.map((item, index) => {
          return (
            <li
              key={index}
              className={item.children.length > 0 ? "has-child" : ""}
            >
              <a href={item.url}>
                <span
                  className="icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                />

                {item.title}
              </a>
              {item.children.length > 0 && renderList(item.children)}
            </li>
          );
        })}
      </ul>
    );
  }

  if (props.position == "top") {
    return <div className="top-menu">{renderList(menuItems)}</div>;
  } else {
    return (
      <div className="side-menu">
        {menuItems.map((item, index) => (
          <NavigationDrawerItem
            key={index}
            title={item.title}
            icon={
              <span
                className="icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.icon }}
              />
            }
            href={item.url}
          />
        ))}
      </div>
    );
  }
}

export default QHNav;
