/* eslint-disable react/no-multi-comp */
import React from "react";

import Drawer, { DrawerSide, DrawerType } from "../Drawer";
import NavbarLogoWrapper from "../Logo/NavbarLogoWrapper";

import styles from "./NavigationDrawer.module.scss";

import NavigationDrawerBody from "./NavigationDrawerBody";

const NavigationDrawer = () => {
  return (
    <Drawer
      type={DrawerType.Navigation}
      side={DrawerSide.Left}
      header={
        <div className={styles.centerVertically}>
          <div className={styles.leftCTA}>
            <NavbarLogoWrapper />
          </div>
        </div>
      }
    >
      <NavigationDrawerBody />
    </Drawer>
  );
};

export default NavigationDrawer;
