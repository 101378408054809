function getTimeDiff(first, second) {
  let currentTime = new Date();

  let first_visit_time = new Date(first);
  let timeDiff1 = currentTime.getTime() - first_visit_time.getTime();
  let minutesDiff = timeDiff1 / (1000 * 60);

  let second_visit_time = new Date(second);
  let timeDiff2 = currentTime.getTime() - second_visit_time.getTime();
  let daysDiff = timeDiff2 / (1000 * 60 * 60 * 24);

  return { minutesDiff, daysDiff };
}

export function initModal(showModal) {
  const first_visit = localStorage.getItem("qh_first_visit");
  const second_visit = localStorage.getItem("qh_second_visit");

  const { minutesDiff, daysDiff } = getTimeDiff(first_visit, second_visit);

  // add entry to sessionStorage
  sessionStorage.setItem("qh_source", "qhp");

  if (sessionStorage.getItem("qh_source") == "qhp") {
    if (first_visit && second_visit) {
      if (daysDiff > 30) {
        showModal();
      }
    } else if (first_visit && !second_visit) {
      if (minutesDiff > 60) {
        showModal();
      }
    } else if (!first_visit && !second_visit) {
      showModal();
    }
  }
}

export function onModalClose() {
  const first_visit = localStorage.getItem("qh_first_visit");
  const second_visit = localStorage.getItem("qh_second_visit");

  let { minutesDiff, daysDiff } = getTimeDiff(first_visit, second_visit);

  if (!first_visit) {
    localStorage.setItem("qh_first_visit", new Date());
  }

  if (!second_visit) {
    localStorage.setItem("qh_second_visit", new Date());
  }

  if (first_visit && minutesDiff > 60) {
    localStorage.setItem("qh_first_visit", new Date());
  }

  if (second_visit && daysDiff > 30) {
    localStorage.setItem("qh_second_visit", new Date());
  }
}
