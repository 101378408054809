import React from "react";

import useTranslation from "next-translate/useTranslation";

import styles from "./NavigationDrawerBody.module.scss";

import QHNav from "../../QHNav";

const NavigationDrawerBody = () => {
  const { t } = useTranslation("common");
  return (
    <div className={styles.listItemsContainer}>
      <h3 className={styles.subtitle}>{t("menu")}</h3>
      <QHNav />
    </div>
  );
};

export default NavigationDrawerBody;
