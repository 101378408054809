
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useEffect } from "react";

import { DirectionProvider } from "@radix-ui/react-direction";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { DefaultSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import { useRouter } from "next/router";
import useSWRImmutable from "swr/immutable";

import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import UserAccountModal from "@/components/Auth/UserAccountModal";
import DeveloperUtility from "@/components/DeveloperUtility/DeveloperUtility";
// import DonatePopup from '@/components/DonatePopup/DonatePopup';
import FontPreLoader from "@/components/Fonts/FontPreLoader";
import GlobalListeners from "@/components/GlobalListeners";
import Navbar from "@/components/Navbar/Navbar";
import SessionIncrementor from "@/components/SessionIncrementor";
import ThirdPartyScripts from "@/components/ThirdPartyScripts/ThirdPartyScripts";
// import Footer from "@/dls/Footer/Footer";
import ToastContainerProvider from "@/dls/Toast/ToastProvider";
import ReduxProvider from "@/redux/Provider";
import { API_HOST } from "@/utils/api";
import { getUserProfile } from "@/utils/auth/api";
import { makeUserProfileUrl } from "@/utils/auth/apiPaths";
import { isLoggedIn } from "@/utils/auth/login";
import { logAndRedirectUnsupportedLogicalCSS } from "@/utils/css";
import * as gtag from "@/utils/gtag";
import { getDir } from "@/utils/locale";
import { createSEOConfig } from "@/utils/seo";
import DataContext from "src/contexts/DataContext";
import ThemeProvider from "src/styles/ThemeProvider";
import { AudioPlayerMachineProvider } from "src/xstate/AudioPlayerMachineContext";
import QHModalBookDemoClass from "@/components/QHModalBookDemoClass";

import "src/styles/reset.scss";
import "src/styles/fonts.scss";
import "src/styles/theme.scss";
import "src/styles/global.scss";
import "src/styles/variables.scss";
import "src/components/Navbar/QHNav.css";
import "/src/components/QHModalBookDemoClass/qh-modal-book-demo-class.css";

function MyApp({ Component, pageProps }): JSX.Element {
  const router = useRouter();
  const { locale } = router;
  const { t } = useTranslation("common");
  const { data: userData } = useSWRImmutable(
    isLoggedIn() ? makeUserProfileUrl() : null,
    async () => {
      const response = await getUserProfile();
      return response;
    }
  );

  // listen to in-app changes of the locale and update the HTML dir accordingly.
  useEffect(() => {
    document.documentElement.dir = getDir(locale);
    logAndRedirectUnsupportedLogicalCSS();
  }, [locale]);

  // Record page view to Google analytics when user navigate to a new page.
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="https://read.quranehakeem.com/images/logo/Logo@192x192.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="preconnect" href={API_HOST} />
      </Head>
      <FontPreLoader locale={locale} />
      <DirectionProvider dir={getDir(locale)}>
        <TooltipProvider>
          <ToastContainerProvider>
            <DataContext.Provider value={pageProps.chaptersData}>
              <AudioPlayerMachineProvider>
                <ReduxProvider locale={locale}>
                  <ThemeProvider>
                    <UserAccountModal
                      requiredFields={userData?.requiredFields}
                      announcement={userData?.announcement}
                    />
                    <DefaultSeo
                      {...createSEOConfig({
                        locale,
                        description: t("default-description"),
                      })}
                    />
                    <GlobalListeners />
                    <Navbar />
                    <DeveloperUtility />
                    <Component {...pageProps} />
                    <AudioPlayer />
                    {/* <Footer /> */}
                    {/* <DonatePopup /> */}
                  </ThemeProvider>
                  <SessionIncrementor />
                </ReduxProvider>
              </AudioPlayerMachineProvider>
            </DataContext.Provider>
          </ToastContainerProvider>
        </TooltipProvider>
      </DirectionProvider>
      <ThirdPartyScripts />
      <QHModalBookDemoClass />
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  