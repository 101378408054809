import React, { memo } from "react";

import useTranslation from "next-translate/useTranslation";
import { useDispatch } from "react-redux";

import LanguageSelector from "../LanguageSelector";
import NavbarLogoWrapper from "../Logo/NavbarLogoWrapper";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import SearchDrawer from "../SearchDrawer/SearchDrawer";
import SettingsDrawer from "../SettingsDrawer/SettingsDrawer";

import styles from "./NavbarBody.module.scss";
// import ProfileAvatarButton from "./ProfileAvatarButton";

import Button, { ButtonShape, ButtonVariant } from "@/dls/Button/Button";
import IconMenu from "@/icons/menu.svg";
import IconSearch from "@/icons/search.svg";
import IconSettings from "@/icons/settings.svg";
import {
  setIsSearchDrawerOpen,
  setIsNavigationDrawerOpen,
  setIsSettingsDrawerOpen,
} from "@/redux/slices/navbar";
import { logEvent } from "@/utils/eventLogger";
import QHNav from "./../QHNav";

/**
 * Log drawer events.
 *
 * @param {string} drawerName
 */
const logDrawerOpenEvent = (drawerName: string) => {
  // eslint-disable-next-line i18next/no-literal-string
  logEvent(`drawer_${drawerName}_open`);
};

const NavbarBody: React.FC = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const openNavigationDrawer = () => {
    logDrawerOpenEvent("navigation");
    dispatch({ type: setIsNavigationDrawerOpen.type, payload: true });
  };

  const openSearchDrawer = () => {
    logDrawerOpenEvent("search");
    dispatch({ type: setIsSearchDrawerOpen.type, payload: true });
  };

  const openSettingsDrawer = () => {
    logDrawerOpenEvent("settings");
    dispatch({ type: setIsSettingsDrawerOpen.type, payload: true });
  };
  return (
    <div className={styles.itemsContainer}>
      <div className={styles.centerVertically}>
        <div className={styles.leftCTA}>
          <>
            <Button
              tooltip={t("menu")}
              variant={ButtonVariant.Ghost}
              shape={ButtonShape.Circle}
              onClick={openNavigationDrawer}
              ariaLabel={t("aria.nav-drawer-open")}
            >
              <IconMenu />
            </Button>
            <NavigationDrawer />
          </>
          <NavbarLogoWrapper />
        </div>
      </div>
      <QHNav position="top" />
      <div className={styles.centerVertically}>
        <div className={styles.rightCTA}>
          <>
            {/* <ProfileAvatarButton /> */}
            <LanguageSelector />
            <Button
              tooltip={t("settings.title")}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Ghost}
              onClick={openSettingsDrawer}
              ariaLabel={t("aria.change-settings")}
            >
              <IconSettings />
            </Button>
            <SettingsDrawer />
          </>
          <>
            <Button
              tooltip={t("search.title")}
              variant={ButtonVariant.Ghost}
              onClick={openSearchDrawer}
              shape={ButtonShape.Circle}
              shouldFlipOnRTL={false}
              ariaLabel={t("search.title")}
            >
              <IconSearch />
            </Button>
            <SearchDrawer />
          </>
        </div>
      </div>
    </div>
  );
};

export default memo(NavbarBody);
