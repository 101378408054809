import { useEffect, useState } from "react";
import Modal from "react-modal";
import ModalContentStep1 from "./ModalContentStep1";
import ModalContentStep2 from "./ModalContentStep2";
import { initModal, onModalClose } from "./ModalHandler";
import { useRouter } from "next/router";

Modal.setAppElement("#__next");

export default function QHModalBookDemoClass() {
  const router = useRouter();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [bookTrialClicked, setBookTrialClicked] = useState(false);
  const [timer, setTimer] = useState(0);
  const [tiemrIntervalId, setTimerIntervalId] = useState(null);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setBookTrialClicked(false);
    setIsOpen(false);

    onModalClose();
  }

  function handleBookTrialBtn() {
    setBookTrialClicked(true);
  }

  function showModal() {
    openModal();
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("utm_source");

    if (source && source == "qhp") {
      const interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);

      setTimerIntervalId(interval);
    }
  }, []);

  useEffect(() => {
    if (timer > 30) {
      clearInterval(tiemrIntervalId);
      initModal(showModal);
    }
  }, [router.pathname, router.query, timer]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName={
          bookTrialClicked
            ? "form-modal qh-modal-overlay"
            : "timeline-modal qh-modal-overlay"
        }
      >
        <div className="container">
          <span className="qh-close-modal" onClick={closeModal}>
            <svg
              height="18"
              viewBox="0 0 311 311.077"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.035 311.078c-4.097 0-8.195-1.558-11.308-4.695-6.25-6.25-6.25-16.383 0-22.633L283.789 4.687c6.25-6.25 16.383-6.25 22.633 0s6.25 16.383 0 22.637L27.363 306.383a16.045 16.045 0 0 1-11.328 4.695zm0 0" />
              <path d="M295.117 311.078a15.879 15.879 0 0 1-11.308-4.695L4.727 27.324c-6.25-6.254-6.25-16.386 0-22.636s16.382-6.25 22.636 0L306.422 283.75c6.25 6.25 6.25 16.383 0 22.633-3.137 3.117-7.23 4.695-11.305 4.695zm0 0" />
            </svg>
          </span>
          {bookTrialClicked ? (
            <ModalContentStep2 />
          ) : (
            <ModalContentStep1 value={{ handleBookTrialBtn }} />
          )}
        </div>
      </Modal>
    </>
  );
}
