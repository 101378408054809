import React from "react";

import GoogleAnalyticsScript from "./GoogleAnalyticsScript";
import HotjarScript from "./HotjarScript";

const ThirdPartyScripts = () => (
  <>
    <GoogleAnalyticsScript /> <HotjarScript />
  </>
);

export default ThirdPartyScripts;
