import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SubmitLoader from "./SubmitLoader";

function ModalContentStep2() {
  const [Countries, setCountries] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submiMsg, setSubmiMsg] = useState(null);
  const [submiError, setSubmiError] = useState(false);

  useEffect(() => {
    (async function () {
      let res = await fetch(
        "https://quranehakeem.com/wp-json/wpr/gform_fields?form_id=6"
      );

      res = await res.json();

      let countries = res.filter((item) =>
        item.class.includes("qh-course-select")
      );
      setCountries(countries[0].choices);
    })();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setSubmiMsg(null);
    setSubmitLoading(true);

    // Get user ip
    let user_ip = await fetch("https://api.ipify.org?format=json");
    user_ip = await user_ip.json();
    user_ip = user_ip.ip;
    data.ip = user_ip;

    // Submit form
    let response = await fetch("/api/qh_form_book_demo_class", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    response = await response.json();

    response = response.response;

    setSubmitLoading(false);

    if (!response.is_valid) {
      setSubmiError(true);
      setSubmiMsg("Sorry! We are unable to send your message");
    } else {
      setSubmiError(false);
      setSubmiMsg("Your message is sent successfully");
    }
  };

  return (
    <div className="qh-user-reg-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <select
          className={errors.course ? "invalid" : ""}
          {...register("course", { required: "Please select a course" })}
        >
          <option value="">Select Course</option>
          {Countries &&
            Countries.map((country, index) => (
              <option key={index} value={country.value}>
                {country.text}
              </option>
            ))}
        </select>
        <p className="error">{errors?.course?.message}</p>
        <input
          type="text"
          className={errors.name ? "invalid" : ""}
          placeholder="Your Name"
          {...register("name", {})}
        />
        <p className="error">{errors?.name?.message}</p>
        <input
          type="tel"
          className={errors.phone ? "invalid" : ""}
          placeholder="Phone"
          {...register("phone", { required: "Please provide a phone number" })}
        />
        <p className="error">{errors?.phone?.message}</p>
        <input
          type="email"
          className={errors.email ? "invalid" : ""}
          placeholder="Email"
          {...register("email", {})}
        />
        <p className="error">{errors?.email?.message}</p>

        <div className="submit-container">
          <input className="btn btn-primary" type="submit" />
          <div className="loader">{submitLoading && <SubmitLoader />}</div>
        </div>
        {submiMsg && (
          <p
            className={`submit-msg alert ${
              submiError == true ? "alert-danger" : "alert-success"
            }`}
          >
            {submiMsg}
          </p>
        )}
      </form>
    </div>
  );
}

export default ModalContentStep2;
